import { imagesUrl } from '@/assets/images/imageUrls'
import Image from '@/common/Image'
import { Outlet, useLocation } from 'react-router'

const Images = {
  'sign-in': imagesUrl.signIn,
  'sign-up': imagesUrl.signUp,
}
const AuthenticationLayout = () => {
  const { pathname } = useLocation()
  const imagePath = pathname.split('/').pop()
  return (
    <article className='bg-white min-h-[90svh] h-[100svh] max-h-[100svh] overflow-hidden w-full px-8 flex flex-col xl:grid grid-rows-1 xl:grid-cols-2 xl:gap-y-[60px] xl:gap-x-12 xl:p-0'>
      <section className='overflow-auto xl:col-start-2 xl:row-start-1 xl:row-span-2  xl:justify-start md:gap-y-10 md:pt-10   flex flex-col scroller'>
        <header className='w-full flex items-center justify-center pt-[60px] xl:justify-start '>
          <div className='hidden md:flex  justify-center items-center  '>
            <Image src='/logo.png' className='w-[79px] h-full' />
          </div>
        </header>

        <main className='w-full flex-1 md:max-w-[770px] m-auto   '>
          <Outlet />
        </main>
      </section>
      <aside
        style={{
          backgroundImage: `url(${
            Images[imagePath as 'sign-in'] || imagesUrl.signIn
          })`,
          backgroundSize: '100% 100vh',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 0%',
        }}
        className='hidden xl:flex col-start-1 col-span-1 row-span-2 bg-black'
      ></aside>
    </article>
  )
}

export default AuthenticationLayout
