/* eslint-disable react-hooks/exhaustive-deps */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import PublicRoutes from '@/routes/PublicRoutes'
import ProtectedRoutes from '@/routes/ProtectedRoutes'
import AuthenticationLayout from '@/layouts/auth'
import SuccessLayout from '@/layouts/success'
import ApplicationLayout from '@/layouts/app'
import SignIn from '@/pages/auth/SignIn'
import ChangePassword from '@/pages/auth/ChangePassword'
import Setup from '@/pages/auth/Setup'
import { Pages } from './route.constant'
import { lazy } from 'react'
import ErrorLayout from '@/layouts/error'
import EventsPage from '@/pages/events'

const StartupDetails = lazy(() => import('@/pages/startup/details'))
const Investor = lazy(() => import('@/pages/investor'))
const InvestorDetails = lazy(() => import('@/pages/investor/details'))
const Startups = lazy(() => import('@/pages/startup'))
const UsersRoles = lazy(() => import('@/pages/usersAndRoles'))
const Profile = lazy(() => import('@/pages/profile'))
const Notifications = lazy(() => import('@/pages/notification'))
const SingleUser = lazy(() => import('@/pages/startup/SingleUser'))

const EventDetails = lazy(() => import('@/pages/events/EventDetails'))

const CommunitiesPage = lazy(() => import('@/pages/community'))
const CommunityDetails = lazy(
  () => import('@/pages/community/CommunityDetails')
)

const FundsPage = lazy(() => import('@/pages/funds'))
const FundDetails = lazy(() => import('@/pages/funds/FundDetails'))

const ReportPage = lazy(() => import('@/pages/report'))
const CatchAllAuthRoute = () => {
  return <Navigate to={`/auth/${Pages.SignIn}`} />
}

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<div>App Crash</div>}>
      <Route element={<PublicRoutes />}>
        <Route path='/auth' element={<AuthenticationLayout />}>
          <Route index element={<Navigate to={`/auth/${Pages.SignIn}`} />} />
          <Route path={Pages.SignIn} element={<SignIn />} />

          <Route path={Pages.ChangePassword} element={<ChangePassword />} />
        </Route>
        <Route path='*' element={<CatchAllAuthRoute />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<ProtectedRoutes />}>
          <Route path='/success' element={<SuccessLayout />}>
            <Route index element={<Setup />} />
          </Route>
          <Route path='/app' element={<ApplicationLayout />}>
            <Route index element={<Navigate to={`/app/${Pages.Event}`} />} />
            <Route path={Pages.Event}>
              <Route index element={<EventsPage />} />
              <Route path=':eventName' element={<EventDetails />} />
            </Route>
            <Route path={Pages.Community}>
              <Route index element={<CommunitiesPage />} />
              <Route path=':community' element={<CommunityDetails />} />
            </Route>
            <Route path={Pages.Fund}>
              <Route index element={<FundsPage />} />
              <Route path=':fund' element={<FundDetails />} />
            </Route>
            <Route path={Pages.Report} element={<ReportPage />} />
            <Route path={Pages.Startups}>
              <Route index element={<Startups />} />
              <Route path={`${Pages.User}/:id`} element={<SingleUser />} />
              <Route path={':startup'} element={<StartupDetails />} />
            </Route>
            <Route path={Pages.Notification} element={<Notifications />} />
            <Route path={Pages.Investors}>
              <Route index element={<Investor />} />
              <Route path={':investor'} element={<InvestorDetails />} />
            </Route>
            <Route path={Pages.UsersRoles} element={<UsersRoles />} />
            <Route path={Pages.Profile} element={<Profile />} />
          </Route>
        </Route>
        <Route path='*' element={<Navigate to='/error/not-found' />} />
      </Route>
      <Route path='/error' element={<ErrorLayout />}>
        <Route path='not-found' element={<div>Not found</div>} />
        <Route path='app-crash' element={<div>App Crash</div>} />
      </Route>
    </Route>
  )
)

export default RouteConfig
