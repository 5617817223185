import composeEventHandlers from '@/helpers/composeEventHandlers'
import { HTMLAttributes, ReactNode, useRef } from 'react'
import Button from './Button'

interface Props extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'reset' | 'submit'
  delay?: number
  size?: 'lg' | 'md' | 'sm' | 'xs'
  color?: 'primary' | 'white' | 'grey'
  startIcon?: ReactNode
  endIcon?: ReactNode
  accept?: string
  multiple?: boolean
  max?: number
}
const ImportButton = (props: Props) => {
  const { onClick, onChange, children, accept, multiple, max, ...rest } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const clickHandler = () => {
    inputRef.current?.click()
  }
  return (
    <div>
      <Button onClick={composeEventHandlers(onClick, clickHandler)} {...rest}>
        {children}
      </Button>
      <input
        onChange={composeEventHandlers(onChange)}
        type='file'
        hidden
        accept={accept}
        ref={inputRef}
        max={max}
        multiple={multiple}
      />
    </div>
  )
}

export default ImportButton
