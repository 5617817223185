import { Link } from 'react-router-dom'

import NavItem from '../NavItem'
import UserInfo from '../UserInfo'

import Image from '@/common/Image'
import { MenuLists } from '../../dashboard.constant'
import Notifications from '../header/Notifications'
import { Pages } from '@/routes/route.constant'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

const LaptopMenu = () => {
  return (
    <>
      <section className=' h-screen  flex flex-col justify-start py-8 px-4 min-w-full overflow-auto'>
        <Link to={`/app/network`} className='active:scale-95 mr-auto'>
          <Image
            src='/logo.png'
            className='h-[64px] w-[56px]  object-cover object-center'
          />
        </Link>
        <nav>
          <ul role='menu' className='flex flex-col gap-4 py-10'>
            {MenuLists.map((list) =>
              list.hasPermission ? (
                <Permission
                  permissions={[
                    PermissionNames[
                      `Can View ${
                        list.name === 'Businesses' ? 'Startups' : list.name
                      }` as 'Can View Investors'
                    ],
                  ]}
                  key={list.name}
                >
                  <NavItem key={list.name} {...list} />
                </Permission>
              ) : (
                <NavItem key={list.name} {...list} />
              )
            )}
          </ul>
        </nav>
        <footer className='flex-1 w-full flex justify-end justify-self-end  flex-col gap-8 mb-10'>
          <nav className='w-full'>
            <ul role='menu' className='flex flex-col justify-end  gap-2'>
              {/* <NavItem
                icon={iconComponents.navigation.SettingIcon}
                name='Settings'
                url={`/app/setting`}
              /> */}
              <NavItem
                icon={Notifications}
                name='Notifications'
                url={`/app/${Pages.Notification}`}
                hasPermission={false}
              />
            </ul>
          </nav>
          <UserInfo />
        </footer>
      </section>
    </>
  )
}

export default LaptopMenu
