import Button from '@/common/button/Button'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/common/tabs'
import clsx from 'clsx'
import { FC, Fragment, ReactElement, useState } from 'react'
import AllEvents from './AllEvents'
import CreatedEvents from './CreatedEvents'
import { Status } from '@/apis/eventApis'

type Props = {
  search: string
  dates: [string, string]
  filter: Status | ''
}
type ViewsType = 'all' | 'created'

const Views = ['all', 'created']
const Contents: FC<Props> = (props) => {
  const [selectedTab, setSelectedTab] = useState<ViewsType>('all')
  const ViewsToElementMap: Record<
    ViewsType,
    { title: string; content: (props: Props) => ReactElement }
  > = {
    all: {
      title: 'All Events',
      content: AllEvents,
    },

    created: {
      title: 'Created Events',
      content: CreatedEvents,
    },
  }
  return (
    <main className='w-full flex-1 flex flex-col gap-0'>
      <Tabs
        onChange={(e: any) => {
          setSelectedTab(e.currentTarget.value)
        }}
        value={selectedTab}
        className='w-full gap-5 flex flex-col'
      >
        <TabList className='flex justify-between  p-2 items-center self-stretch w-full rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-75 max-w-[368px]'>
          {Views.map((view, i) => {
            return (
              <Tab as='span' key={view + i} value={view} className='flex-1'>
                <Button
                  className={clsx(
                    'flex-1 text-center w-full whitespace-nowrap',
                    selectedTab === view
                      ? 'border border-sifuse-shades-900'
                      : 'text-sm leading-5 !font-normal !border-none !bg-sifuse-shades-75'
                  )}
                  size='sm'
                  color={selectedTab === view ? 'primary' : 'white'}
                >
                  {ViewsToElementMap[view as ViewsType].title}
                </Button>
              </Tab>
            )
          })}
        </TabList>

        <TabPanels>
          {Views.map((view, i) => {
            const Comp = ViewsToElementMap[view as ViewsType].content
            return (
              <Fragment key={view + i}>
                <TabPanel value={view} as='section'>
                  <div className='flex flex-col gap-4 '>
                    <Comp {...props} />
                  </div>
                </TabPanel>
              </Fragment>
            )
          })}
        </TabPanels>
      </Tabs>
    </main>
  )
}

export default Contents
