import { useCallback, useMemo, useState } from 'react'

import { contextFactory } from '../contexts/helpers/contextFactory'
import { AuthUser } from '@/apis/authApis'
import { Storage } from '@/helpers/Storage'
import { AdminUser } from '@/apis/userRoleApis'

type User = AuthUser &
  AdminUser & {
    role: { id: number; name: string; permissions: number[] }
  }
type AuthValues = {
  getAccessToken: () => string
  getAuthState: () => Partial<User> | undefined
}
type AuthActions = {
  setAccessToken: (at: string) => void
  setAuthState: (authState: Partial<User>) => void
  logOut: () => void
}
const [useAuthContext, AuthContext] = contextFactory<AuthValues>()
const [useAuthActionsContext, AuthActionsContext] =
  contextFactory<AuthActions>()

export { useAuthContext, useAuthActionsContext }

interface Props {
  children: React.ReactNode
}

const userData = Storage.getItem('USER_DATA')
const permissions = Storage.getItem('USER_PERMISSIONS')

const AuthContextProvider = (props: Props) => {
  const { children } = props
  const [authData, setAuthData] = useState<User>(
    userData && permissions
      ? {
          ...userData,
          permissions,
        }
      : undefined
  )

  const getAccessToken = useCallback(() => {
    return authData?.access || ''
  }, [authData])
  const setAccessToken = useCallback(
    (at: string) =>
      setAuthData((prev) => ({ ...(prev ? prev : {}), access: at } as any)),
    []
  )
  const getAuthState = useCallback(() => authData, [authData])
  const setAuthState = useCallback((data: Partial<User>) => {
    setAuthData((prev) => {
      const userData = { ...(prev ? prev : {}), ...data } as any
      const { access, refresh, permissions, ...rest } = userData
      Storage.setItem('USER_DATA', rest)
      Storage.setItem('USER_PERMISSIONS', permissions)
      return userData
    })
  }, [])

  const logOut = useCallback(() => {
    setAuthData(undefined as any)
    Storage.removeItem('USER_DATA')
    Storage.removeItem('USER_PERMISSIONS')
  }, [])

  const values = useMemo(() => {
    return {
      getAccessToken,
      getAuthState,
    }
  }, [getAccessToken, getAuthState])
  const actions = useMemo(
    () => ({
      setAccessToken,
      setAuthState,
      logOut,
    }),
    [setAccessToken, setAuthState, logOut]
  )
  return (
    <AuthContext.Provider value={values}>
      <AuthActionsContext.Provider value={actions}>
        {' '}
        {children}
      </AuthActionsContext.Provider>{' '}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
