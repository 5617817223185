import { PaginationReq } from '@/apis/api.types'
import { EVENTS, TOP_EVENTS } from '@/apis/endpoints'
import {
  CreateEvent,
  DeleteEvent,
  GetEventAttendees,
  GetEventDetail,
  GetEvents,
  GetTopEvents,
  RegisterOrCancelEvent,
  UpdateEvent,
} from '@/apis/eventApis'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetTopEvents = () => {
  return useQuery([TOP_EVENTS], () => GetTopEvents())
}
export const useGetEvents = (data: PaginationReq) => {
  return useInfiniteQuery(
    [EVENTS, data],
    ({ pageParam = 1 }) => GetEvents({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
    }
  )
}

export const useGetEventDetail = (event: number) => {
  return useQuery([EVENTS, event], () => GetEventDetail({ event }), {
    enabled: !!event,
  })
}

export const useCreateEvent = () => {
  const qc = useQueryClient()
  return useMutation(CreateEvent, {
    onSuccess() {
      qc.invalidateQueries([EVENTS])
    },
  })
}

export const useUpdateEvent = () => {
  const qc = useQueryClient()
  return useMutation(UpdateEvent, {
    onSuccess(data) {
      qc.invalidateQueries([EVENTS, data.data.data.id])
    },
  })
}

export const useDeleteEvent = () => {
  const qc = useQueryClient()
  return useMutation(DeleteEvent, {
    onSuccess() {
      qc.invalidateQueries([EVENTS])
    },
  })
}

export const useRegisterOrCancelEvent = () => {
  const qc = useQueryClient()
  return useMutation(RegisterOrCancelEvent, {
    onSuccess(_, variables) {
      qc.invalidateQueries([EVENTS, variables.event])
      qc.invalidateQueries([`${EVENTS}${variables.event}/attendees/`])
    },
  })
}

export const useGetAttendees = (data: PaginationReq) => {
  return useInfiniteQuery(
    [`${EVENTS}${data.event}/attendees/`, data],
    ({ pageParam = 1 }) => GetEventAttendees({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
      enabled: !!data.event,
    }
  )
}
