import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import { FC } from 'react'

type Props = {
  title: string
  subText?: string
}
const EmptyScreen: FC<Props> = (props) => {
  return (
    <section className='w-full flex flex-col px-6  gap-6  justify-center items-center flex-1 h-full min-h-[60vh] bg-sifuse-shades-50 rounded-lg'>
      <iconComponents.util.EmptyEventsIcon />
      <hgroup className='flex flex-col  items-center'>
        <Typography heading='xs'>{props.title}</Typography>
        <Typography heading='4xs' color='#979797'>
          {props.subText}
        </Typography>
      </hgroup>
    </section>
  )
}
export default EmptyScreen
