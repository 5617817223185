import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { Pages } from '@/routes/route.constant'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import DateItem from '../DateItem'
import clsx from 'clsx'
import { Event, Status } from '@/apis/eventApis'

type EventProps = {
  event: Event
}

const EventStatusColorMap: Record<Status, string> = {
  ongoing: 'bg-[#00CB84] !text-[#464650]',
  upcoming: 'bg-[#FAA005] !text-[#464650]',
  concluded: 'bg-[#272343] !text-[#F3FBFB]',
}
const EventCard: FC<EventProps> = (props) => {
  const { event } = props
  const navigate = useNavigate()

  return (
    <section className='rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-50 w-full self-stretch'>
      <div
        style={{
          backgroundImage: `url(${event.banner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='w-full p-3 h-[183px] rounded-lg object-cover object-center flex justify-end items-start'
      >
        <Typography
          heading='4xs'
          className={clsx(
            'capitalize  w-[90px] h-[30px] rounded-lg py-2.5 px-7 flex justify-center items-center',
            EventStatusColorMap[event.status.toLowerCase() as Status]
          )}
        >
          {event.status}
        </Typography>
      </div>
      <main className='flex flex-col items-start gap-4 p-6 flex-1'>
        <Typography as='h4' button='md' color='#22222A'>
          {event.name}
        </Typography>
        <section className='w-full flex flex-col gap-4 items-start'>
          <DateItem title='Start Date' date={event.start} />
          <DateItem title='End Date' date={event.end} />
          <Typography
            paragraph='sm'
            color='var(--shades600)'
            className='flex items-center gap-1 w-full -ml-1'
          >
            <iconComponents.util.LocationIcon />
            <span>{event.location}</span>
          </Typography>
        </section>
      </main>
      <footer className='flex flex-col items-start gap-4 p-6'>
        <Button
          onClick={() =>
            navigate(`/app/${Pages.Event}/${event.name}`, {
              state: { event: event.id },
            })
          }
          color='white'
          size='xs'
          className='w-full'
        >
          View Event
        </Button>
      </footer>
    </section>
  )
}

export default EventCard
