import iconComponents from '@/assets/icons/iconComponents'
import { MenuItem } from './dashboard.types'
import { Pages } from '@/routes/route.constant'

export const MenuLists: MenuItem[] = [
  {
    name: 'Events',
    icon: iconComponents.navigation.EventIcon,
    url: `/app/${Pages.Event}`,
    hasPermission: false,
  },
  {
    name: 'Businesses',
    icon: iconComponents.navigation.StartupIcon,
    url: `/app/${Pages.Startups}`,
    hasPermission: true,
  },
  {
    name: 'Investors',
    icon: iconComponents.navigation.NetworkIcon,
    url: `/app/${Pages.Investors}`,
    hasPermission: true,
  },
  {
    name: 'Community',
    icon: iconComponents.navigation.FileIcon,
    url: `/app/${Pages.Community}`,
    hasPermission: false,
  },
  {
    name: 'Funds',
    icon: iconComponents.navigation.FundIcon,
    url: `/app/${Pages.Fund}`,
    hasPermission: true,
  },
  {
    name: 'Reports',
    icon: iconComponents.navigation.ReportIcon,
    url: `/app/${Pages.Report}`,
    hasPermission: false,
  },
  {
    name: 'User Roles & Permissions',
    icon: iconComponents.navigation.RolesIcon,
    url: `/app/${Pages.UsersRoles}`,
    hasPermission: true,
  },

  {
    name: 'Profile',
    icon: iconComponents.navigation.ProfileIcon,
    url: `/app/${Pages.Profile}`,
    hasPermission: false,
  },
]
