import MobileMenu from '../menus/MobileMenu'
import TabletMenu from '../menus/TabletMenu'
import { MenuLists } from '../../dashboard.constant'
import mitt, { Emitter } from 'mitt'
import { BreadcrumbMap } from '../../dashboard.types'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import useGetCssValue from '@/hooks/useGetCssValue'
import Typography from '@/common/Typography'
import useWindowSize from '@/hooks/useWindowSize'
import iconComponents from '@/assets/icons/iconComponents'
import clsx from 'clsx'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { useQueryClient } from 'react-query'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { WEB_SOCKET_URL } from '@/constants/api'

export const breadCrumbsEmitter: Emitter<BreadcrumbMap> = mitt<BreadcrumbMap>()
export const useBreadCrumbs = (crumbsMap: Map<string, string>) => {
  useEffect(() => {
    breadCrumbsEmitter.emit('value', crumbsMap)
  }, [crumbsMap])
}

const Header = () => {
  const { getAccessToken } = useAuthContext()
  const queryClient = useQueryClient()
  const token = getAccessToken()
  useEffect(() => {
    let client
    try {
      client = new W3CWebSocket(`${WEB_SOCKET_URL}/ws/online_status/${token}/`)
    } catch (error: any) {
      console.log(error.message, "Couldn't create connection")
    }
    if (!client) return
    client.onopen = () => {
      console.log('WebSocket Client Connected TO online_status')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer
      console.log(ev.data, 'RECEIVED')
      try {
        dataFromServer = JSON.parse(ev.data)
        if (dataFromServer) {
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [token, queryClient])
  return (
    <div className='w-full relative flex py-1 px-4 items-center self-stretch justify-between h-[50px] md:flex-col-reverse  md:justify-start md:items-start md:h-[160px] xl:h-16'>
      <Breadcrumbs />
      <div className='flex items-center justify-center xl:hidden w-full'>
        <MobileMenu menuOptions={MenuLists} />
        <TabletMenu menuOptions={MenuLists} />
      </div>
    </div>
  )
}

const Breadcrumbs = () => {
  const [map, setMap] = useState(new Map())
  useEffect(() => {
    breadCrumbsEmitter.on('value', (map) => {
      setMap(map)
    })
    return () => {
      breadCrumbsEmitter.off('value')
    }
  }, [])
  const { state } = useLocation()
  const navigate = useNavigate()
  const [crumbs, reverseMap] = useCrumbs(map, map.get('excludeCount'))
  const windowSize = useWindowSize()
  const [parentRef, parentWidth] = useGetCssValue('width', '100px', [
    windowSize,
  ])
  const ifOnlyCrumb = () => crumbs.length === 1
  const ifLastCrumb = (idx: number) => idx === crumbs.length - 1
  const ifFirstCrumb = (idx: number) => idx === 0

  return ifOnlyCrumb() ? (
    <hgroup
      className='md:h-16 flex  items-center whitespace-nowrap'
      ref={parentRef}
    >
      <Typography
        as='h4'
        className='text-base text-[#000014] font-medium leading-[22px] xl:text-[20px] xl:leading-7 '
      >
        {crumbs[0]}
      </Typography>
    </hgroup>
  ) : (
    <hgroup
      className='md:h-16 flex flex-1 justify-start items-center gap-1 overflow-hidden text-ellipsis whitespace-nowrap'
      ref={parentRef}
    >
      {crumbs.map((pathName, idx) =>
        ifFirstCrumb(idx) ? (
          <div
            key={pathName}
            className='flex justify-start items-center gap-1 cursor-pointer'
          >
            <button
              onClick={() =>
                navigate(reverseMap.get(crumbs[crumbs.length - 2])!, { state })
              }
            >
              <iconComponents.navigation.BreadcrumbBackIcon className='stroke-jaa-shades-gray-2 w-6 h-6' />
            </button>
            <Typography
              as='span'
              onClick={() => navigate(reverseMap.get(pathName)!, { state })}
              className='text-base text-[#000014] font-medium leading-[22px] xl:text-[20px] xl:leading-7 capitalize'
            >
              {parseInt(parentWidth) > window.innerWidth ? '...' : crumbs[0]}
            </Typography>
          </div>
        ) : (
          <div key={pathName} className='flex justify-start items-center gap-1'>
            <Typography
              as='span'
              className={clsx('w-2 h-6')}
              heading='2xs'
              color='var(--mainprimary)'
            >
              /
            </Typography>
            <Typography
              as='span'
              button={windowSize.width < 640 ? 'md' : 'lg'}
              className={clsx(
                'cursor-pointer !text-sifuse-shades-600  sm:font-medium max-w-[147px] md:max-w-[205px] lg:max-w-[362px] whitespace-nowrap overflow-hidden text-ellipsis',
                !ifLastCrumb(idx) && 'hover:!text-sifuse-shades-900'
              )}
              onClick={() =>
                ifLastCrumb(idx)
                  ? null
                  : navigate(reverseMap.get(pathName)!, { state })
              }
            >
              {crumbs[idx]}
            </Typography>
          </div>
        )
      )}
    </hgroup>
  )
}

function useCrumbs(
  pathMap: Map<string, string>,
  ignoreCount: number = 1
): [string[], Map<string, string>] {
  const res: string[] = []
  const newPaths = new Map()
  for (let entry of pathMap) {
    const [path, name] = entry
    const remainingPathItems = path
      .split('/')
      .filter((x) => x)
      .slice(ignoreCount)

    res[remainingPathItems.length - 1] = name
    newPaths.set(name, path)
  }
  return [res, newPaths]
}

export default Header
