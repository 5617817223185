import { useBreadCrumbs } from '@/layouts/app/components/header'
import Header from './components/home/Header'
import { useState } from 'react'
import Contents from './components/home/Contents'
import { Status } from '@/apis/eventApis'

const BreadCrumbMap = new Map()
BreadCrumbMap.set('/app/events', 'Events')
const EventsPage = () => {
  useBreadCrumbs(BreadCrumbMap)
  const [searchInput, setSearchInput] = useState('')
  const [filter, setFilter] = useState<Status | ''>('')
  const [dates, setDates] = useState<[string, string]>(['', ''])
  return (
    <article className='w-full flex-1 flex flex-col gap-6'>
      <Header
        search={searchInput}
        setSearch={setSearchInput}
        dates={dates}
        setDates={setDates}
        filter={filter}
        setFilter={setFilter}
      />
      <Contents search={searchInput} dates={dates} filter={filter} />
    </article>
  )
}

export default EventsPage
