import iconComponents from '@/assets/icons/iconComponents'
import clsx from 'clsx'
import { AllHTMLAttributes } from 'react'
import InputWithAdornment from './InputWithAdornment'

interface ISearch extends AllHTMLAttributes<HTMLInputElement> {}

function SearchInput(props: ISearch) {
  const {
    height,
    placeholder = 'Search',
    className,
    style = {},
    ...rest
  } = props
  return (
    <InputWithAdornment
      style={{
        border: '1px solid var(--shades-gray-1)',
        padding: '0px 14px',
        backgroundColor: 'white',
        ...style,
      }}
      className={clsx(
        'py-2.5 px-3.5 bg-jaa-shades-white rounded-lg border border-jaa-shades-gray-1 h-11 min-w-[250px]',
        className
      )}
      left={<iconComponents.util.SearchIcon />}
      height={height as string}
      placeholder={placeholder}
      {...rest}
    />
  )
}
export default SearchInput
