import React, { useState, useEffect } from 'react'
import checkPermission from './checkPermission'
import {
  Debug,
  EntityOwnerId,
  Permissions,
  User,
  PermissionType,
} from './permission.types'

import { useAuthContext } from '@/contexts/AuthContextProvider'

export type PermissionProps = {
  children: React.ReactElement
  noAccess?:
    | React.ReactElement
    | ((args: { user: User | null; hasAccess: boolean }) => React.ReactElement)
  permissions: Permissions
  type?: PermissionType
  entityOwnerId?: EntityOwnerId
  debug?: Debug
}
const Permission = (props: PermissionProps) => {
  const {
    children,
    noAccess,
    entityOwnerId,
    permissions = [],
    type = 'one-of',
    debug = false,
  } = props
  const { getAuthState, getAccessToken } = useAuthContext()

  const currentUserPermissions: Permissions | undefined =
    getAuthState()?.role?.permissions
  const currentUser = getAuthState()

  const [hasAccess, setHasAccess] = useState(
    currentUser
      ? checkPermission(
          {
            ...currentUser,
            permissions: currentUserPermissions || [],
          },
          permissions,
          {
            type,
            entityOwnerId,
            debug,
          }
        )
      : false
  )
  useEffect(() => {
    const user: User | null = {
      ...currentUser,
      permissions: currentUserPermissions || [],
    }
    if (!getAccessToken()) {
      setHasAccess(false)
      return
    }
    const doesHaveAccess = checkPermission(user, permissions, {
      type,
      entityOwnerId,
      debug,
    })
    setHasAccess(!!doesHaveAccess)
  }, [
    entityOwnerId,
    permissions,
    type,
    currentUserPermissions,
    currentUser,
    debug,
    getAccessToken,
  ])

  const renderNoAccess = () => {
    if (typeof noAccess === 'function') {
      return noAccess({
        user: {
          ...currentUser,
          permissions: currentUserPermissions || [],
        },
        hasAccess,
      })
    }
    return noAccess
  }
  return hasAccess ? children : renderNoAccess() || null
}

export default Permission
