export const SIGNIN = '/users/login/'
export const SIGNUP = '/users/signup/'
export const VERIFY_ACCOUNT = '/users/verify-email/'
export const RESEND_VERIFICATION_LINK = '/users/resend-verification-email/'
export const REFRESH = '/users/refresh/'
export const LOGOUT = '/users/logout/'
export const FORGOT_PASSWORD = '/users/forgot-password/'
export const RESET_PASSWORD = '/users/reset-password/'
export const CHANGE_PASSWORD = '/users/change-password/'

export const USERS = '/users/'
export const INVESTORS_HIGHLIGHTS = '/users/investor-highlights/'
export const INVESTOR_GROUPS = '/users/investors/groups/'
export const ADMIN_USERS = '/users/admins/'
export const ADMIN_ROLES = '/users/admins/roles/'
export const PERMISSIONS = '/users/admins/roles/permissions/'

export const INDUSTRIES = '/startups/industries/'
export const COUNTRIES = '/startups/countries/'
export const PRODUCT_TYPES = '/startups/product-types/'

// network module apis
export const DENY_CONNECTION = '/networks/connections/'
export const GET_POSTS = '/networks/posts/'

export const GET_STARTUPS = '/startups/'
export const GET_STARTUPS_HIGHLIGHTS = '/startups/highlights/'
export const STARTUP_ASSESSMENT = '/startups/assessments/'
export const STARTUP_ASSESSMENT_DATA = '/startups/assessments/data/'
export const GET_USERS = '/users/'
export const INVESTOR_PORTFOLIOS = '/portfolios/'
export const SEARCH_TIMELINE = '/networks/timeline/search/'
export const TRENDING_STARTUPS = '/networks/timeline/startup-suggestions/'
export const RECOMMENDED_INVESTORS = '/networks/timeline/investor-suggestions/'
export const CONNECTIONS = `/networks/connections/`
export const POSTS = `/networks/posts/`
export const GET_TIMELINE_ACTIVITIES = `/networks/timeline/activity/`
export const POST_MEDIA = `/networks/posts/media/`
export const POST_COMMENT = `/networks/posts/comments/`
export const MENTIONS = `/networks/timeline/mentions/`

export const NOTIFICATION = '/notifications/'
export const MARK_NOTIFICATION_AS_READ = '/notifications/mark-all-as-read/'
export const GET_UNREAD_NOTIFICATION = '/notifications/unread-count/'
export const REGISTER_DEVICE = '/notifications/webpush/'
//socket
export const WEB_SOCKET_POST = `wss://api.sifuse.heckerbellaapps.com/ws/post/`

export const EVENTS = '/events/'
export const TOP_EVENTS = '/events/top-three/'

export const COMMUNITIES = '/communities/'
export const COMMUNITY_MEMBERS = '/communities/members/'

export const FUNDS = '/funds/'
export const FUNDS_INVESTMENT = '/funds/portfolios/investments/'

export const ADMIN_REPORTS = '/users/admins/reports/'
