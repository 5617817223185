import { CircleLoader, ClipLoader, FadeLoader } from 'react-spinners'
import useGetCssValue from '@/hooks/useGetCssValue'

import { useOutsideClick } from '@/hooks/useOutsideClick'
import clsx from 'clsx'
import React, {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from 'react'

interface ISelectInput extends HTMLAttributes<HTMLSelectElement> {
  optionsValue?: (item: any) => string
  optionsText?: (item: any) => React.ReactNode
  optionsDisplay?: (item: any) => React.ReactNode
  options: any[]
  name?: string
  fullWidth?: boolean
  error?: string
  value?: string
  minWidth?: string
  menuClassName?: string
  disabled?: boolean
  loading?: boolean
}
const SelectInput: FC<ISelectInput> = ({
  placeholder,
  optionsValue,
  optionsText,
  optionsDisplay,
  options,
  onChange,
  name,
  id,
  className,
  menuClassName,
  fullWidth,
  error,
  value,
  minWidth,
  disabled,
  loading,
  ...rest
}) => {
  const [menuTopPosition, setMenuTopPosition] = useState(58)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selected, setSelected] = useState(() =>
    placeholder
      ? typeof options[0] === 'string'
        ? placeholder
        : (() => {
            const option = options[0]
            if (!option) return placeholder
            const foundLabelKey = Object.keys(option).find((key: string) => {
              return optionsText?.(option) === option[key]
            })
            const foundValueKey = Object.keys(option).find((key: string) => {
              return optionsValue?.(option) == option[key]
            })
            if (foundLabelKey && foundValueKey)
              return {
                [foundLabelKey]: placeholder,
                [foundValueKey]: '',
              }
            return {
              label: placeholder,
              value: '',
            }
          })()
      : options[0]
  )
  const valueMap = useMemo(() => {
    const map: Record<string, any> = {}
    for (let opt of options) {
      if (optionsValue) {
        map[optionsValue(opt)] = opt
      } else {
        map[opt] = opt
      }
    }

    return map
  }, [options.length])

  // const menuRef = useRef<HTMLUListElement>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  anchorRef.current = anchorEl
  const clickHandler: MouseEventHandler = (e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget as HTMLButtonElement)
  }
  const [menuRefEle, menuHeight] = useGetCssValue(
    'height',
    '350px',
    Boolean(anchorEl)
  )
  useOutsideClick([anchorRef, menuRefEle], () => setAnchorEl(null))

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (anchorEl) {
        const menu = menuRefEle.current

        for (let opt = 0; opt < options.length; opt++) {
          if (menu?.children[opt]) {
            menu.children[opt].dispatchEvent(
              new MouseEvent('mouseleave', { bubbles: true })
            )
          }
          if (
            (optionsValue ? optionsValue(options[opt]) : options[opt])
              ?.toString()
              ?.toLowerCase()
              ?.startsWith(e.key.toLowerCase())
          ) {
            menu?.children[placeholder ? opt + 1 : opt]?.scrollIntoView()
            if (menu?.children[placeholder ? opt + 1 : opt]) {
              menu.children[placeholder ? opt + 1 : opt].focus()
              menu.children[placeholder ? opt + 1 : opt].dispatchEvent(
                new MouseEvent('mouseover', { bubbles: true })
              )
              menu.children[placeholder ? opt + 1 : opt].dispatchEvent(
                new MouseEvent('mouseenter', { bubbles: true })
              )
            }
          }
        }
      }
    }
    window.addEventListener('keydown', keydownHandler)

    return () => {
      window.removeEventListener('keydown', keydownHandler)
    }
  }, [anchorEl, menuRefEle, options, placeholder])

  useEffect(() => {
    if (value && options.length) {
      setSelected(valueMap[value])
    }
  }, [value, valueMap, options])

  useEffect(() => {
    const heightFromTop =
      outerRef.current && outerRef.current.getBoundingClientRect().top
    if (!heightFromTop || !Boolean(anchorEl)) return
    if (parseInt(menuHeight) + heightFromTop + 58 > window.innerHeight) {
      setMenuTopPosition(-parseInt(menuHeight))
    } else {
      setMenuTopPosition(58)
    }
  }, [anchorEl, menuHeight])

  return (
    <div ref={outerRef} className='relative min-w-max'>
      <button
        disabled={disabled || loading}
        type='button'
        onClick={clickHandler}
        id={id}
        className={clsx(
          `flex-1 capitalize flex bg-sifuse-shades-75 justify-between border border-sifuse-shades-200 rounded-lg  items-center text-sifuse-shades-600 h-[52px] py-2 px-4 disabled:fill-sifuse-shades-400 disabled:bg-sifuse-shades-400 disabled:text-sifuse-shades-500 gap-3 !tracking-normal`,
          className ? className : '',
          Boolean(anchorEl) ? 'border-sifuse-shades-400' : '',
          fullWidth ? 'w-full' : '',
          error ? 'border border-wb-error-500' : '',
          (optionsText ? optionsText(selected) : selected) === placeholder
            ? 'text-sifuse-shades-200'
            : ''
        )}
      >
        <span>
          {optionsDisplay || optionsText
            ? optionsDisplay?.(selected) || optionsText?.(selected)
            : selected}
        </span>
        {loading ? (
          <ClipLoader color='var(--shades200)' size={22} />
        ) : (
          <svg
            width='13'
            height='12'
            viewBox='0 0 13 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{
              transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            onClick={(e) => {
              if (disabled) return
              if (anchorEl) e.stopPropagation()
              setAnchorEl(null)
            }}
          >
            <mask id='path-1-inside-1_1472_9062' fill='white'>
              <path d='M6.33326 8.39982C5.98326 8.39982 5.63326 8.26482 5.36826 7.99982L2.10826 4.73982C1.96326 4.59482 1.96326 4.35482 2.10826 4.20982C2.25326 4.06482 2.49326 4.06482 2.63826 4.20982L5.89826 7.46982C6.13826 7.70982 6.52826 7.70982 6.76826 7.46982L10.0283 4.20982C10.1733 4.06482 10.4133 4.06482 10.5583 4.20982C10.7033 4.35482 10.7033 4.59482 10.5583 4.73982L7.29826 7.99982C7.03326 8.26482 6.68326 8.39982 6.33326 8.39982Z' />
            </mask>
            <path
              d='M6.33326 8.39982C5.98326 8.39982 5.63326 8.26482 5.36826 7.99982L2.10826 4.73982C1.96326 4.59482 1.96326 4.35482 2.10826 4.20982C2.25326 4.06482 2.49326 4.06482 2.63826 4.20982L5.89826 7.46982C6.13826 7.70982 6.52826 7.70982 6.76826 7.46982L10.0283 4.20982C10.1733 4.06482 10.4133 4.06482 10.5583 4.20982C10.7033 4.35482 10.7033 4.59482 10.5583 4.73982L7.29826 7.99982C7.03326 8.26482 6.68326 8.39982 6.33326 8.39982Z'
              fill='#6D6D77'
            />
            <path
              d='M5.36826 7.99982L4.66115 8.70693L5.36826 7.99982ZM2.10826 4.73982L2.81537 4.03272L2.81537 4.03272L2.10826 4.73982ZM2.63826 4.20982L1.93116 4.91693L2.63826 4.20982ZM5.89826 7.46982L6.60537 6.76272L5.89826 7.46982ZM6.76826 7.46982L7.47537 8.17693H7.47537L6.76826 7.46982ZM10.0283 4.20982L9.32115 3.50272L9.32115 3.50272L10.0283 4.20982ZM10.5583 4.73982L9.85116 4.03272L9.85115 4.03272L10.5583 4.73982ZM7.29826 7.99982L8.00537 8.70693H8.00537L7.29826 7.99982ZM6.33326 7.39982C6.23931 7.39982 6.14644 7.36379 6.07537 7.29272L4.66115 8.70693C5.12008 9.16586 5.72722 9.39982 6.33326 9.39982V7.39982ZM6.07537 7.29272L2.81537 4.03272L1.40115 5.44693L4.66115 8.70693L6.07537 7.29272ZM2.81537 4.03272C3.06089 4.27824 3.06089 4.67141 2.81537 4.91693L1.40115 3.50272C0.865631 4.03824 0.865631 4.91141 1.40115 5.44693L2.81537 4.03272ZM2.81537 4.91693C2.56984 5.16246 2.17668 5.16246 1.93116 4.91693L3.34537 3.50272C2.80984 2.96719 1.93668 2.96719 1.40115 3.50272L2.81537 4.91693ZM1.93116 4.91693L5.19115 8.17693L6.60537 6.76272L3.34537 3.50272L1.93116 4.91693ZM5.19115 8.17693C5.82168 8.80746 6.84484 8.80746 7.47537 8.17693L6.06116 6.76272C6.21168 6.61219 6.45484 6.61219 6.60537 6.76272L5.19115 8.17693ZM7.47537 8.17693L10.7354 4.91693L9.32115 3.50272L6.06115 6.76272L7.47537 8.17693ZM10.7354 4.91693C10.4898 5.16246 10.0967 5.16246 9.85116 4.91693L11.2654 3.50272C10.7298 2.96719 9.85668 2.96719 9.32115 3.50272L10.7354 4.91693ZM9.85116 4.91693C9.60563 4.67141 9.60563 4.27824 9.85116 4.03272L11.2654 5.44693C11.8009 4.91141 11.8009 4.03824 11.2654 3.50272L9.85116 4.91693ZM9.85115 4.03272L6.59116 7.29272L8.00537 8.70693L11.2654 5.44693L9.85115 4.03272ZM6.59116 7.29272C6.52008 7.36379 6.42722 7.39982 6.33326 7.39982V9.39982C6.93931 9.39982 7.54644 9.16585 8.00537 8.70693L6.59116 7.29272Z'
              fill='#6D6D77'
              mask='url(#path-1-inside-1_1472_9062)'
            />
          </svg>
        )}
      </button>
      {error && <small className='text-wb-error-500'>{error}</small>}
      {Boolean(anchorEl) && (
        <ul
          style={{
            top: `${menuTopPosition}px`,
          }}
          ref={menuRefEle}
          className={`flex-col rounded-[5px] min-w-[165px] items-center justify-start bg-white border
          shadow-[0px_4px_4px_0px_#0000001A] border-wb-neutral-200
          absolute left-0 z-30 mr-3 overflow-auto w-max h-max max-h-[350px] ${
            Boolean(anchorEl) ? 'flex' : 'hidden'
          } ${menuClassName || ''}`}
        >
          {placeholder && (
            <li
              className={`p-5 gap-2.5 w-full cursor-pointer whitespace-nowrap relative z-40 left hover:bg-sifuse-main-primary hover:text-sifuse-shades-900 text-sifuse-shades-400`}
              role='menuitem'
              onClick={(e) => {
                setSelected(() =>
                  typeof options[0] === 'string'
                    ? placeholder
                    : (() => {
                        const option = options[0]
                        if (!option) return placeholder
                        const foundLabelKey = Object.keys(option).find(
                          (key: string) => {
                            return optionsText?.(option) === option[key]
                          }
                        )
                        const foundValueKey = Object.keys(option).find(
                          (key: string) => {
                            return optionsValue?.(option) == option[key]
                          }
                        )
                        if (foundLabelKey && foundValueKey)
                          return {
                            [foundLabelKey]: placeholder,
                            [foundValueKey]: '',
                          }
                        return {
                          label: placeholder,
                          value: '',
                        }
                      })()
                )
                onChange?.({
                  target: { name, value: '' },
                  currentTarget: { name, value: '' },
                } as any)
                setAnchorEl(null)
              }}
            >
              {placeholder}
            </li>
          )}
          {options.map((option, index) => {
            return (
              <li
                style={{ minWidth: minWidth || '180px' }}
                key={index}
                className={`hover:bg-sifuse-main-primary hover:text-sifuse-shades-900 text-sifuse-shades-600 p-5 w-full cursor-pointer whitespace-nowrap relative z-40 left flex justify-between items-center gap-2.5
                 ${
                   (optionsValue ? optionsValue(option) : option) ===
                   (optionsValue ? optionsValue(selected) : selected)
                     ? '!bg-sifuse-main-primary !text-sifuse-shades-900'
                     : ''
                 } ${
                  options.length > 20 && index === options.length - 1 ? '' : ''
                }`}
                role='menuitem'
                onMouseEnter={(e) => {
                  e.currentTarget.classList.add('bg-sifuse-main-primary')
                  e.currentTarget.classList.add('text-sifuse-shades-900')
                }}
                onMouseOver={(e) => {
                  e.currentTarget.classList.add('bg-sifuse-main-primary')
                  e.currentTarget.classList.add('text-sifuse-shades-900')
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.classList.remove('bg-sifuse-main-primary')
                  e.currentTarget.classList.remove('text-sifuse-shades-900')
                }}
                onClick={(e) => {
                  setSelected(option)
                  onChange?.({
                    target: {
                      name,
                      value: optionsValue ? optionsValue(option) : option,
                    },
                    currentTarget: {
                      name,
                      value: optionsValue ? optionsValue(option) : option,
                    },
                  } as any)
                  setAnchorEl(null)
                }}
              >
                {optionsText ? optionsText(option) : option}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default SelectInput
