import { ToastNotify } from '@/common/toastManager'

export const readFile = (
  file: File,
  onRead: (binaryStr: string, file: File) => void
) => {
  if (!file) {
    ToastNotify('warn', {
      message: 'File is too large, ensure file is lesser than 140MB',
    })
    return
  }

  const reader = new FileReader()

  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = () => {
    // Do whatever you want with the file contents
    const binaryStr = reader.result
    onRead(binaryStr as string, file)
  }
  reader.readAsDataURL(file)
}
export const readFileAsBlob = (
  file: File,
  onRead: (binaryStr: string, file: File) => void
) => {
  if (!file) {
    ToastNotify('warn', {
      message: 'File is too large, ensure file is lesser than 140MB',
    })
    return
  }

  const reader = new FileReader()

  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = () => {
    // Do whatever you want with the file contents
    const binaryStr = URL.createObjectURL(
      new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      })
    )

    onRead(binaryStr as string, file)
  }
  reader.readAsArrayBuffer(file)
}

export type CustomFile = { name: string; type: string }

export const isImage = (file: File | CustomFile) =>
  file.type.toLowerCase().includes('image') ||
  file.name.endsWith('.svg') ||
  file.name.endsWith('.gif')
export const isVideo = (file: File | CustomFile) =>
  file.type.toLowerCase().includes('video')

export const isDocx = (file: File | CustomFile) =>
  file.type.toLowerCase().includes('application/msword') ||
  file.type
    .toLowerCase()
    .includes(
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) ||
  file.name.endsWith('.doc') ||
  file.name.endsWith('.docx')

export const isExcel = (file: File | CustomFile) =>
  file.name.endsWith('.csv') ||
  file.type.toLowerCase().includes('application/vnd.ms-excel') ||
  file.name.endsWith('.xlsx') ||
  file.name.endsWith('.xls')

export const isPdf = (file: File | CustomFile) =>
  file.type.toLowerCase().includes('application/pdf') ||
  file.name.endsWith('.pdf')

export const isDocument = (file: CustomFile) =>
  isDocx(file) || isPdf(file) || isExcel(file)
export const isMedia = (file: CustomFile) => isVideo(file) || isImage(file)

export const getFileExtension = (fileName: string) =>
  fileName.substring(fileName.lastIndexOf('.') + 1)
export const getFileName = (fileName: string) =>
  fileName.substring(0, fileName.lastIndexOf('.'))
