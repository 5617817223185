import iconComponents from '@/assets/icons/iconComponents'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Title from '@/common/Title'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useToggleState } from '@/hooks/useToggleState'
import { useResetPassword } from '@/store/authStore'
import { FormikHelpers, useFormik } from 'formik'
import { useNavigate } from 'react-router'
import { resetPasswordSchema } from './validators/forgotpassword.validator'
import useQueryParams from '@/hooks/useQueryParams'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'
import { ResetPasswordReqDTO } from '@/apis/authApis'

type ResetPassword = {
  password: string
  confirm_password: string
}
const ChangePassword = () => {
  const { state: showPassword, toggle: togglePassword } = useToggleState(false)
  const { email, token, uid } =
    useQueryParams<Omit<ResetPasswordReqDTO, 'password'>>()
  const navigate = useNavigate()

  const { mutateAsync: resetPasswordAction, isLoading: isReseting } =
    useResetPassword()
  const INITIAL_VALUES: ResetPassword = {
    password: '',
    confirm_password: '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: resetPasswordSchema,
  })
  async function submitHandler(
    values: ResetPassword,
    { setSubmitting, resetForm }: FormikHelpers<ResetPassword>
  ) {
    if (!email) {
      ToastNotify('error', { message: 'Kindly click link again.' })
      return setSubmitting(false)
    }
    const { error, response } = await withAsync(() => {
      return resetPasswordAction({
        email,
        uid,
        token,
        password: values.password,
      })
    })
    if (error) {
      errorHandler(error)
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()
      navigate('/auth')
    }
  }

  return (
    <>
      <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
        <header className='w-full flex items-center justify-center'>
          <Title>Sign In</Title>
          <hgroup className='flex flex-col justify-center items-center'>
            <Typography as='h1' heading='xl' className='text-center'>
              Enter New Pasword
            </Typography>
            <Typography
              as='p'
              paragraph='lg'
              className='text-center text-sifuse-shades-500'
            >
              Description text
            </Typography>
          </hgroup>
        </header>
        <main className='flex flex-col items-start gap-6 self-stretch'>
          <form
            onSubmit={handleSubmit}
            className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
          >
            <FormGroup
              label='New passsword'
              id='password'
              className='col-span-2'
            >
              <InputWithAdornment
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter your passsword'
                right={
                  <button type='button' onClick={togglePassword}>
                    {showPassword ? (
                      <iconComponents.auth.EyeCloseIcon />
                    ) : (
                      <iconComponents.auth.EyeOpenIcon />
                    )}
                  </button>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                name='password'
                value={values.password}
                error={
                  errors.password &&
                  (touched.password as unknown as string) &&
                  errors.password
                }
              />
            </FormGroup>
            <FormGroup
              label='Confirm passsword'
              id='password'
              className='col-span-2'
            >
              <InputWithAdornment
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter your passsword'
                right={
                  <button type='button' onClick={togglePassword}>
                    {showPassword ? (
                      <iconComponents.auth.EyeCloseIcon />
                    ) : (
                      <iconComponents.auth.EyeOpenIcon />
                    )}
                  </button>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                name='confirm_password'
                value={values.confirm_password}
                error={
                  errors.confirm_password &&
                  (touched.confirm_password as unknown as string) &&
                  errors.confirm_password
                }
              />
            </FormGroup>

            <Button
              type='submit'
              color='primary'
              size='xl'
              className='w-full text-center  mt-8 col-span-2'
              loading={isSubmitting || isReseting}
              disabled={!isValid || isSubmitting || isReseting}
            >
              Proceed &amp; Continue
            </Button>
          </form>
        </main>
      </article>
    </>
  )
}

export default ChangePassword
