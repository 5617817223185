export enum Pages {
  SignIn = 'sign-in',
  ChangePassword = 'change-password',
  Profile = 'profile',
  Startups = 'startups',
  User = 'user',
  Investors = 'investors',
  Event = 'events',
  Community = 'communities',
  Fund = 'funds',
  Report = 'reports',
  UsersRoles = 'users',
  Notification = 'notifications',
}
