import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import {
  days,
  getDateString,
  isDayFutureToThatDay,
  isDayPreviousToThatDay,
  isNextMonth,
  isPrevMonth,
} from '@/helpers/date'
import useDisplayDays from '@/hooks/useDisplayDays'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import { useToggleState } from '@/hooks/useToggleState'
import clsx from 'clsx'
import { add, format, isToday } from 'date-fns'
import { FC, useRef, useState } from 'react'
import Time from './Time'

type CalendarProps = {
  hasTime?: boolean
  date?: Date
  onChange?: (date?: Date) => void
  placeholder?: string
  className?: string
  popupClassName?: string
  formatter?: (date: Date) => string
  min?: Date
  max?: Date
}
const Calendar: FC<CalendarProps> = (props) => {
  const {
    hasTime,
    min,
    max,
    date,
    onChange,
    placeholder = '__/__/__',
    className,
    popupClassName,
    formatter = (date) => {
      return format(date, 'do MMM yyyy')
    },
  } = props
  const displayRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const {
    state: showCalendar,
    close: closeCalendar,
    open: openCalendar,
  } = useToggleState()
  const [enteredDate, setEnteredDate] = useState(date)
  const [displayDate, setDisplayDate] = useState(date || new Date())
  const [isTimeEntered, setIsTimeEntered] = useState(false)
  useOutsideClick([displayRef, contentRef], closeCalendar)

  const handleChangeMonth = (date: Date) => {
    setDisplayDate(date)
  }
  const handleChangeDay = (date: Date) => {
    setEnteredDate(date)
    onChange?.(date)
    if ((isTimeEntered && hasTime) || !hasTime) {
      closeCalendar()
    }
  }

  const handleChangeTime = (date: Date) => {
    setIsTimeEntered(true)

    // if (enteredDate) {
    //   closeCalendar()
    // }
    setEnteredDate(date)

    onChange?.(date)
  }
  const clearDateHandler = () => {
    setEnteredDate(undefined)
    onChange?.(null as any)
  }
  console.log(enteredDate, date)
  return (
    <section className={clsx('flex flex-col relative')}>
      <header
        ref={displayRef}
        onClick={openCalendar}
        className={clsx(
          'cursor-pointer min-w-[157px] h-12 rounded-lg py-2 px-4 flex justify-between items-center gap-2.5 bg-sifuse-shades-50 border border-sifuse-shades-200',
          className
        )}
      >
        <Typography
          as='span'
          className='text-sm font-normal leading-5 flex-1 capitalize'
          color='var(--shades600)'
        >
          {enteredDate
            ? formatter?.(enteredDate) || enteredDate.toLocaleString()
            : placeholder}
        </Typography>
        <button
          type='button'
          className='p-1'
          onClick={(e) => {
            if (enteredDate) {
              e.stopPropagation()
              clearDateHandler()
            }
          }}
        >
          {enteredDate ? (
            <iconComponents.util.CancelIcon className='w-3 h-3 stroke-black' />
          ) : (
            <iconComponents.util.CalendarOutlineIcon />
          )}
        </button>
      </header>
      {showCalendar && (
        <article
          ref={contentRef}
          className={clsx(
            'flex flex-col absolute top-14 p-11 gap-8 rounded-lg border border-[#ebebeb] bg-sifuse-shades-50 shadow-[0px_2.3659px_18.92718px_0px_rgba(170,_170,_170,_0.03)]',
            popupClassName
          )}
        >
          <MonthSelector
            hasTime={hasTime}
            onChange={handleChangeMonth}
            date={displayDate}
          />
          <DaySelector
            onChange={handleChangeDay}
            date={displayDate}
            min={min}
            max={max}
            enteredDate={enteredDate}
            key={getDateString(displayDate)}
          />
          {hasTime && (
            <Time date={enteredDate} onChange={handleChangeTime} showLabel />
          )}
        </article>
      )}
    </section>
  )
}

type Selector = {
  onChange: (date: Date) => void
  date: Date
}
const MonthSelector: FC<Selector & { hasTime?: boolean }> = (props) => {
  const { date, onChange, hasTime } = props
  const monthHandler = (dir: 'next' | 'previous') => {
    const newDate = add(date, {
      months: dir === 'next' ? 1 : -1,
    })
    onChange(newDate)
  }

  return (
    <header className='w-full flex flex-col items-start gap-2 justify-between'>
      {hasTime && (
        <Typography as='h3' heading='2xs' color='#333'>
          Date
        </Typography>
      )}
      <section className='w-full flex items-center justify-center gap-4'>
        <Typography
          className='whitespace-nowrap flex-1 text-sm font-bold capitalize '
          color='#333'
        >
          {format(date, 'MMMM yyyy')}
        </Typography>
        <div className='inline-flex items-center gap-2.5'>
          <button type='button' onClick={() => monthHandler('previous')}>
            <iconComponents.util.ChevronDownIcon className='rotate-90' />
          </button>
          <button type='button' onClick={() => monthHandler('next')}>
            <iconComponents.util.ChevronDownIcon className='-rotate-90' />
          </button>
        </div>
      </section>
    </header>
  )
}

const DaySelector: FC<
  Selector & { min?: Date; max?: Date; enteredDate?: Date }
> = (props) => {
  const { date, onChange, min, max, enteredDate } = props
  const displayDays = useDisplayDays(date)
  const notToBeShownDate = (selectedDate: Date) => {
    return isPrevMonth(selectedDate, date) || isNextMonth(selectedDate, date)
  }
  const isInvalidDate = (selectedDate: Date) => {
    if (min) {
      return isDayPreviousToThatDay(min, selectedDate)
    }
    if (max) {
      return isDayFutureToThatDay(max, selectedDate)
    }
    return false
  }
  const isSameDate = (selectedDate: Date) => {
    if (enteredDate) {
      return getDateString(selectedDate) === getDateString(enteredDate)
    } else return false
  }
  return (
    <main className='grid grid-cols-7 w-[245px] gap-5'>
      {days.map((day, idx) => (
        <span
          className='capitalize text-[#333] text-sm font-medium'
          key={day + idx}
        >
          {day.substring(0, 3)}
        </span>
      ))}
      {displayDays.map((date, idx) => (
        <button
          disabled={notToBeShownDate(date) || isInvalidDate(date)}
          key={date.getDate() + String(idx)}
          onClick={() => {
            onChange(date)
          }}
          type='button'
          className={clsx(
            'text-[#666] text-center text-sm font-medium w-[33px] h-[33px]',
            notToBeShownDate(date) && '!bg-transparent',
            isToday(date) && 'bg-[#FB3F4A] rounded-full !text-white',
            isSameDate(date) && 'bg-sifuse-main-primary rounded-full',
            isInvalidDate(date) && '!cursor-text opacity-40 font-normal'
          )}
        >
          {notToBeShownDate(date) ? null : date.getDate()}
        </button>
      ))}
    </main>
  )
}

export default Calendar
