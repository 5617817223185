export const PermissionNames = {
  'Can View Startups': 1,
  'Can Manage & Update Startups': 2,
  'Can View Investors': 3,
  'Can Manage & Update Investors': 4,
  'Can View Funds': 5,
  'Can View Reports': 6,
  'Can Export Reports': 7,
  'Can View User Roles & Permissions': 8,
  'Can Manage and Invite Users': 9,
  'Can Manage User Roles & Permissions': 10,
}
