import { useMemo } from 'react'
import { daysInMonth } from '@/helpers/date'

const useDisplayDays = (currentDate: Date) => {
  return useMemo(() => {
    const days = []
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1,
      currentDate.getHours()
    )
    const firstDayOfMonthInAWeek = firstDayOfMonth.getDay()
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      daysInMonth(currentDate.getFullYear())[currentDate.getMonth()]
    )
    for (let i = firstDayOfMonthInAWeek; i > 0; i--) {
      days.push(
        new Date(
          firstDayOfMonth.getFullYear(),
          firstDayOfMonth.getMonth(),
          firstDayOfMonth.getDate() - i,
          firstDayOfMonth.getHours()
        )
      )
    }
    for (
      let i = firstDayOfMonth.getDate();
      i <= lastDayOfMonth.getDate();
      i++
    ) {
      days.push(
        new Date(
          firstDayOfMonth.getFullYear(),
          firstDayOfMonth.getMonth(),
          i,
          firstDayOfMonth.getHours()
        )
      )
    }

    if (days.length < 36) {
      const curLength = days.length
      for (let i = 0; i < 35 - curLength; i++) {
        days.push(
          new Date(
            firstDayOfMonth.getFullYear(),
            firstDayOfMonth.getMonth() + 1,
            i + 1,
            firstDayOfMonth.getHours()
          )
        )
      }
    }

    return days
  }, [currentDate])
}

export default useDisplayDays
