import iconComponents from '@/assets/icons/iconComponents'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Title from '@/common/Title'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { useToggleState } from '@/hooks/useToggleState'
import { useLocation, useNavigate } from 'react-router'
import ForgotPasswordPopup from './components/ForgotPasswordPopup'
import { useAuthActionsContext } from '@/contexts/AuthContextProvider'
import { SigninReqDTO } from '@/apis/authApis'
import { useEffect, useState } from 'react'
import { FormikHelpers, useFormik } from 'formik'
import { signinSchema } from './validators/signin.validator'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { flushSync } from 'react-dom'
import { useSignin } from '@/store/authStore'

declare var PasswordCredential: any
const SignIn = () => {
  const { state: showPassword, toggle: togglePassword } = useToggleState(false)
  const {
    state: forgotPassword,
    open: showForgotPassword,
    close: closeForgotPassword,
  } = useToggleState(false)
  const { state: RouteState } = useLocation()
  const { from } = RouteState ?? {}
  const [oldDetails, setOldDetails] = useState<Partial<SigninReqDTO>>({})
  const navigate = useNavigate()
  const { setAuthState } = useAuthActionsContext()

  const { mutateAsync: signinAction, isLoading: isSigining } = useSignin()
  const INITIAL_VALUES: SigninReqDTO = {
    email: oldDetails.email || '',
    password: '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: signinSchema,
    enableReinitialize: true,
  })
  useEffect(() => {
    navigator.credentials
      .get({ mediation: 'optional' })
      .then((cred) => {
        if (cred) {
          setOldDetails({
            email: cred.id,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  async function submitHandler(
    values: SigninReqDTO,
    { setSubmitting, resetForm }: FormikHelpers<SigninReqDTO>
  ) {
    const { error, response } = await withAsync(() => {
      return signinAction({ ...values })
    })
    if (error) {
      errorHandler(error)
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()

      try {
        if (typeof PasswordCredential !== 'undefined') {
          const cred = new PasswordCredential({
            id: response.data.data.email,
            password: values.password,
            name: `${response.data.data.first_name} ${response.data.data.last_name}`,
          })
          navigator.credentials.store(cred).then((creds) => {})
        }
      } catch (err) {
        console.log(err, 'NOT SUpported context')
      }

      flushSync(() =>
        setAuthState({
          ...response.data.data,
        } as any)
      )
      navigate(from || '/success', { replace: true, state: response.data.data })
    }
  }
  return (
    <>
      <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
        <header className='w-full flex items-center justify-center'>
          <Title>Sign In</Title>
          <hgroup className='flex flex-col justify-center items-center'>
            <Typography as='h1' heading='xl' className='text-center'>
              Sign In
            </Typography>
            {/* <Typography
              as='p'
              paragraph='lg'
              className='text-center text-sifuse-shades-500'
            >
              Description text
            </Typography> */}
          </hgroup>
        </header>
        <main className='flex flex-col items-start gap-6 self-stretch'>
          <form
            onSubmit={handleSubmit}
            className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
          >
            <FormGroup
              label='Email address'
              id='email_address'
              className='col-span-2'
            >
              <InputWithAdornment
                type='email'
                placeholder='Enter email address'
                inputMode={'email'}
                autoComplete='username'
                onChange={handleChange}
                onBlur={handleBlur}
                name='email'
                value={values.email}
                error={
                  errors.email &&
                  (touched.email as unknown as string) &&
                  errors.email
                }
              />
            </FormGroup>

            <FormGroup label='Passsword' id='password' className='col-span-2'>
              <InputWithAdornment
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter your passsword'
                right={
                  <button type='button' onClick={togglePassword}>
                    {showPassword ? (
                      <iconComponents.auth.EyeCloseIcon />
                    ) : (
                      <iconComponents.auth.EyeOpenIcon />
                    )}
                  </button>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                name='password'
                value={values.password}
                error={
                  errors.password &&
                  (touched.password as unknown as string) &&
                  errors.password
                }
              />
            </FormGroup>
            <FormGroup
              id='forgot_action'
              className='flex justify-start items-end w-full col-span-2 !gap-0 '
            >
              <Typography
                as='button'
                type='button'
                onClick={showForgotPassword}
                className='text-sifuse-shades-500 text-base leading-6 hover:underline active:scale-95 hover:text-sifuse-shades-800'
              >
                Forgot Password
              </Typography>
            </FormGroup>
            <Button
              type='submit'
              color='primary'
              size='xl'
              className='w-full text-center  mt-8 col-span-2'
              loading={isSubmitting || isSigining}
              disabled={!isValid || isSubmitting || isSigining}
            >
              Sign in
            </Button>
          </form>
        </main>
      </article>
      <ForgotPasswordPopup
        onClose={closeForgotPassword}
        open={forgotPassword}
      />
    </>
  )
}

export default SignIn
