import iconComponents from '@/assets/icons/iconComponents'
import Typography from '@/common/Typography'
import { format } from 'date-fns'

const DateItem = (props: { title: string; date: string }) => {
  const parsedDate = Date.parse(props.date)
    ? format(new Date(props.date), 'E do MMM, yyyy; hh:mmaaa')
    : props.date
  return (
    <hgroup className='flex flex-col items-start gap-2 w-full'>
      <Typography as='h5' label='sm' color='#979797'>
        {props.title}
      </Typography>
      <Typography
        as='p'
        paragraph='sm'
        className='flex items-center gap-1 whitespace-nowrap'
        color='var(--shades700)'
      >
        <iconComponents.util.CalendarIcon />
        <span>{parsedDate}</span>
      </Typography>
    </hgroup>
  )
}

export default DateItem
