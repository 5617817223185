import { Event } from '@/apis/eventApis'
import ApiState from '@/common/ApiState'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import useAutoSlider from '@/hooks/useAutoSlider'
import useMediaQuery from '@/hooks/useMediaQuery'
import { Pages } from '@/routes/route.constant'
import { useGetTopEvents } from '@/store/eventStore'
import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router'

const TopEvents = () => {
  const { data: topEvents, isLoading } = useGetTopEvents()
  const navigate = useNavigate()
  const { step: eventDisplaying, setStep: setEventDisplaying } = useAutoSlider(
    topEvents?.length || 1,
    5000
  )

  const isMobile = useMediaQuery('(max-width: 640px)')
  const currentEvent = topEvents?.[eventDisplaying] || ({} as Event)
  return (
    <ApiState
      isLoading={isLoading}
      isEmpty={topEvents?.length === 0}
      emptyComponent={<div></div>}
    >
      <section
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 41.07%, #000 100%), url(${
            currentEvent.banner || '/defaultCompany.svg'
          }), linear-gradient(180deg, lightgray 50%, #000 100%), url(${
            currentEvent.banner || '/defaultCompany.svg'
          }), linear-gradient(180deg, lightgray 50%, #000 100%)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='rounded-lg border mb-5 border-[rgba(46,_91,_255,_0.10)] w-full h-[405px] md:h-[475px] p-5 md:p-7 lg:p-10 flex flex-col justify-end items-start gap-5'
      >
        <div className='w-full flex justify-between items-end'>
          <hgroup className='flex flex-col gap-1 max-w-[281px] md:max-w-[480px]'>
            <Typography
              heading={isMobile ? 'md' : '2xs'}
              color='var(--shades50)'
            >
              {currentEvent.name}
            </Typography>
            <Typography
              paragraph={isMobile ? 'sm' : 'lg'}
              color='var(--shades50)'
            >
              {currentEvent.description}
            </Typography>
          </hgroup>
          {!isMobile && (
            <Button
              onClick={() =>
                navigate(`/app/${Pages.Event}/${currentEvent.name}`, {
                  state: { event: currentEvent.id },
                })
              }
              size='xl'
              className='min-w-[195px]'
            >
              View Event
            </Button>
          )}
        </div>
        <div className='w-full flex items-center justify-between md:justify-center'>
          <Indicator
            numberOfSteps={topEvents?.length || 1}
            setStep={setEventDisplaying}
            step={eventDisplaying}
          />
          {isMobile && (
            <Button
              onClick={() =>
                navigate(`/app/${Pages.Event}/${currentEvent.name}`, {
                  state: { event: currentEvent.id },
                })
              }
              className='min-w-[113px]'
              size='sm'
            >
              View Event
            </Button>
          )}
        </div>
      </section>
    </ApiState>
  )
}

type IndicatorProps = {
  step: number
  setStep: (step: number) => void
  numberOfSteps: number
}
const Indicator: FC<IndicatorProps> = (props) => {
  const { step, setStep, numberOfSteps } = props
  return (
    <section className='flex h-1.5 items-start gap-2'>
      {Array.from({ length: numberOfSteps }, (_v, k) => k).map((val) => (
        <div
          onClick={() => setStep(val)}
          key={val}
          className={clsx(
            'flex w-6 h-1 px-4 cursor-pointer flex-col justify-center items-center gap-2.5 rounded-2xl bg-sifuse-main-primary',
            step === val ? 'opacity-100' : 'opacity-30'
          )}
        ></div>
      ))}
    </section>
  )
}
export default TopEvents
