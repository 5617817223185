import useMediaQuery from '@/hooks/useMediaQuery'
import Sidebar from './Sidebar'
import Modal from './Modal'
import { IOpenable } from './modal.type'
import { ReactNode } from 'react'

const SideModal = (
  props: IOpenable & {
    size?: 'xl' | 'lg' | 'sm' | 'md'
    children: ReactNode
    reEvaluate?: boolean
  }
) => {
  const isLaptop = useMediaQuery('(min-width: 1024px)')
  const ContainerComponent = isLaptop ? Sidebar : Modal
  return (
    <ContainerComponent
      onClose={props.onClose}
      variant={props.size || 'md'}
      open={props.open}
      disableBackdrop
      reEvaluate={props.reEvaluate}
    >
      {props.children}
    </ContainerComponent>
  )
}

export default SideModal
