import { Event } from '@/apis/eventApis'
import iconComponents from '@/assets/icons/iconComponents'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import Textarea from '@/common/Input/Textarea'
import Calendar from '@/common/Input/date/Calendar'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import ImportButton from '@/common/button/ImportButton'
import ModalHeader from '@/common/modal/ModalHeader'
import SideModal from '@/common/modal/SideModal'
import { IOpenable } from '@/common/modal/modal.type'
import { readFile } from '@/helpers/files'
import { FileData } from '@/types/file.type'
import { add, format, sub } from 'date-fns'
import { FormikHelpers, useFormik } from 'formik'
import { FC, FormEvent, useEffect, useState } from 'react'
import { eventSchema } from '../../validators/event.validator'
import { useCreateEvent, useUpdateEvent } from '@/store/eventStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'

type Values = Omit<Event, 'id' | 'created' | 'updated' | 'status' | 'attending'>
const CreateOrUpdateEvent: FC<IOpenable<Partial<Event>>> = (props) => {
  const { data, onClose, open } = props
  const { mutateAsync: createEventAction, isLoading: isCreatingEvent } =
    useCreateEvent()
  const { mutateAsync: updateEventAction, isLoading: isUpdatingEvent } =
    useUpdateEvent()

  const InitialValues: Values = {
    banner: '',
    description: '',
    end: '',
    start: '',
    link: '',
    name: '',
    location: '',
    ...(data ? data : {}),
  }
  const {
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    validateForm,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: InitialValues,
    onSubmit: submitHandler,
    validationSchema: eventSchema(!!data),
  })
  async function submitHandler(
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>
  ) {
    setSubmitting(true)
    const fd = new FormData()
    if (data) {
      fd.set('id', String(data.id))
    }
    Object.entries(values)
      .filter((entry) => entry[0] !== 'attending')
      .forEach((entry) => {
        if (entry[0] === 'banner' && typeof entry[1] === 'string') {
          return
        }
        fd.set(entry[0], entry[1] as string)
      })
    const { error, response } = await withAsync(
      () => (data ? updateEventAction(fd) : createEventAction(fd)),
      () => setSubmitting(false)
    )
    if (error) {
      return errorHandler(error)
    }
    if (response) {
      onClose()
      resetForm()
      ToastNotify('success', { message: response.data.message })
    }
  }

  return (
    <SideModal open={open} onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        title={data ? 'Edit Event' : 'Create Event'}
      />
      <main className='p-6 w-full'>
        <form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className='w-full flex flex-col gap-4'
        >
          <FormGroup id='name' label='Event Name'>
            <InputWithAdornment
              placeholder='Enter event name'
              onChange={handleChange}
              onBlur={handleBlur}
              name='name'
              value={values.name}
              error={
                errors.name &&
                (touched.name as unknown as string) &&
                errors.name
              }
            />
          </FormGroup>
          <FormGroup id='description' label='Description'>
            <Textarea
              placeholder='Enter event description'
              rows={5}
              onChange={handleChange}
              onBlur={handleBlur}
              name='description'
              value={values.description}
              error={
                errors.description &&
                (touched.description as unknown as string) &&
                errors.description
              }
            />
          </FormGroup>
          <div className='grid grid-cols-1 md:grid-cols-2 w-full gap-x-5 gap-y-4'>
            <FormGroup id='start-date' label='Start Date & Time'>
              <div className='w-full flex flex-col gap-2'>
                <Calendar
                  hasTime
                  min={add(new Date(), { days: 1 })}
                  placeholder='Pick Start Date'
                  date={values.start ? new Date(values.start) : undefined}
                  popupClassName='z-40 mb-10'
                  className='bg-sifuse-shades-75 h-[52px] border px-4 transition-[height,_border-color] duration-[500,_200] align-middle flex items-center relative py-2 gap-[10px] rounded-lg border-sifuse-shades-200 focus-within:border-sifuse-shades-400'
                  formatter={(date) => format(date, 'dd MMM yyyy; hh:mmaaa')}
                  onChange={(date) =>
                    setFieldValue('start', date ? date.toISOString() : '')
                  }
                />
                <small className='text-[var(--mainred)] z-10'>
                  {errors.start &&
                    (touched.start as unknown as string) &&
                    errors.start}
                </small>
              </div>
            </FormGroup>
            <FormGroup id='end-date' label='End Date & Time'>
              <div className='w-full flex flex-col gap-2'>
                <Calendar
                  hasTime
                  min={
                    values.start
                      ? sub(new Date(values.start), { days: 1 })
                      : add(new Date(), { days: 1 })
                  }
                  date={values.end ? new Date(values.end) : undefined}
                  placeholder='Pick End Date'
                  popupClassName='z-40 mb-10 md:right-0'
                  className='bg-sifuse-shades-75 h-[52px] border px-4 transition-[height,_border-color] duration-[500,_200] align-middle flex items-center relative py-2 gap-[10px] rounded-lg border-sifuse-shades-200 focus-within:border-sifuse-shades-400'
                  formatter={(date) => format(date, 'dd MMM yyyy; hh:mmaaa')}
                  onChange={(date) =>
                    setFieldValue('end', date ? date.toISOString() : '')
                  }
                />
                <small className='text-[var(--mainred)] z-10'>
                  {errors.end &&
                    (touched.end as unknown as string) &&
                    errors.end}
                </small>
              </div>
            </FormGroup>
          </div>
          <FormGroup id='location' label='Location'>
            <InputWithAdornment
              placeholder='Enter address'
              onChange={handleChange}
              onBlur={handleBlur}
              name='location'
              value={values.location}
              error={
                errors.location &&
                (touched.location as unknown as string) &&
                errors.location
              }
            />
          </FormGroup>
          <FormGroup id='link' label='Relevant Link to Event'>
            <InputWithAdornment
              placeholder='http(s)://'
              onChange={handleChange}
              onBlur={handleBlur}
              name='link'
              value={values.link}
              error={
                errors.link &&
                (touched.link as unknown as string) &&
                errors.link
              }
            />
          </FormGroup>
          <FileExplorer
            onChange={(file) => {
              setFieldValue('banner', file)
              setTimeout(() => validateForm(), 600)
            }}
            error={
              errors.banner &&
              (touched.banner as unknown as string) &&
              errors.banner
            }
            src={typeof values.banner === 'string' ? values.banner : undefined}
            file={typeof values.banner !== 'string' ? values.banner : undefined}
          />
          <footer className='w-full py-4 flex items-center justify-start gap-4'>
            <Button
              startIcon={
                data ? undefined : (
                  <iconComponents.util.AddSquareIcon className='w-6 h-6' />
                )
              }
              size='lg'
              type='submit'
              className='min-w-[120px]'
              disabled={isSubmitting || isCreatingEvent || isUpdatingEvent}
              loading={isSubmitting || isCreatingEvent || isUpdatingEvent}
            >
              {data ? 'Save & Update' : 'Create Event'}
            </Button>
            {data && (
              <Button
                disabled={isSubmitting || isCreatingEvent || isUpdatingEvent}
                type='reset'
                onClick={onClose}
                size='lg'
                color='white'
              >
                Close
              </Button>
            )}
          </footer>
        </form>
      </main>
    </SideModal>
  )
}

type FileProps = FileData & {
  onChange: (file: File) => void
  error?: string
}

const FileExplorer: FC<FileProps> = (props) => {
  const { error, src, file, onChange } = props
  const [image, setImage] = useState<FileData>({ src, file })
  const handleChange = (e: FormEvent) => {
    const file = (e.currentTarget as any).files?.[0]
    if (file) {
      readFile(file, (bs, _file) => {
        onChange(_file)
        setImage({ src: bs, file: _file })
      })
    }
  }
  useEffect(() => {
    if (src) setImage({ src })
  }, [src])
  return (
    <section className='flex flex-col gap-2 justify-start items-start'>
      {image.src ? (
        <div className='flex flex-col gap-4 max-w-[380px] w-full'>
          <div
            style={{
              backgroundImage: `url(${image.src}), linear-gradient(180deg, lightgray 50%, #000 100%)`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className='rounded flex justify-end items-start h-[100px] pt-2.5 pr-2 w-[256px]'
          >
            <button
              onClick={() => {
                setImage({ src: undefined, file: undefined })
                onChange(null as any)
              }}
              className='flex justify-center items-center bg-[#f1f3f4] rounded-sm py-0.5 px-2 w-[22px] h-[22px] '
            >
              <iconComponents.util.CancelIcon
                stroke='black'
                className='w-4 h-4'
              />
            </button>
          </div>
          <ImportButton
            onChange={handleChange}
            startIcon={<iconComponents.util.GalleryAddIcon />}
            accept='.jpg, .png, .jpeg'
            max={20 * 1024 * 1024}
            color='white'
            size='xs'
          >
            Choose another image
          </ImportButton>
        </div>
      ) : (
        <div className='flex w-full max-w-[380px] p-4 flex-col justify-center items-center gap-2 rounded-lg border border-sifuse-shades-200 bg-sifuse-shades-50'>
          <Typography heading='3xs' color='var(--shades800)'>
            Upload Event Banner
          </Typography>
          <Typography
            paragraph='xs'
            className='text-center max-w-[276px]'
            color='#979797'
          >
            Upload format lncude (hint: .JPG, .PNG, .JPEG, less than 20mb size )
          </Typography>
          <ImportButton
            onChange={handleChange}
            startIcon={<iconComponents.util.GalleryAddIcon />}
            accept='.jpg, .png, .jpeg'
            max={20 * 1024 * 1024}
            color='white'
            size='xs'
          >
            choose file
          </ImportButton>
        </div>
      )}
      {error && (
        <Typography
          color='#EA3829'
          heading='4xs'
          className='whitespace-nowrap text-ellipsis overflow-hidden font-normal'
        >
          {error}
        </Typography>
      )}
    </section>
  )
}

export default CreateOrUpdateEvent
