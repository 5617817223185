import SearchInput from '@/common/Input/SearchInput'
import Title from '@/common/Title'
import Button from '@/common/button/Button'
import { FC } from 'react'
import CreateOrUpdateEvent from './CreateOrUpdateEvent'
import { useToggleState } from '@/hooks/useToggleState'
import Calendar from '@/common/Input/date/Calendar'
import { add } from 'date-fns'
import useMediaQuery from '@/hooks/useMediaQuery'
import MoreButton from '@/common/button/MoreButton'
import { Status } from '@/apis/eventApis'
import { ucword } from '@/helpers/strings'
import iconComponents from '@/assets/icons/iconComponents'

type HeaderProps = {
  search: string
  dates: [string, string]
  filter: Status | ''
  setDates: (dates: [string, string]) => void
  setSearch: (str: string) => void
  setFilter: (str: Status | '') => void
}

const Header: FC<HeaderProps> = ({
  dates,
  search,
  filter,
  setDates,
  setSearch,
  setFilter,
}) => {
  const {
    state: showCreateEvent,
    open: openPopup,
    close: closePopup,
  } = useToggleState()
  const isTablet = useMediaQuery('(min-width: 760px) and (max-width: 900px)')
  return (
    <header className='flex flex-col md:flex-row gap-4 justify-between items-start md:items-center'>
      <Title>Events</Title>
      <section className='max-h-[48px]'>
        <SearchInput
          placeholder='Search Events'
          className='!h-12'
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </section>
      <section className='flex items-center justify-start  md:justify-end gap-5 flex-wrap md:flex-nowrap content-start '>
        <MoreButton
          value={filter}
          placeholder='Filter by'
          menuClassName='left-auto -right-2 !top-14'
          className='flex flex-row items-center gap-2.5 rounded-lg !bg-sifuse-shades-50 border border-sifuse-shades-200 min-w-[115px] max-w-fit h-12 px-4 py-2'
          CustomComp={
            <button type='button'>
              <span className='flex-1 capitalize text-sifuse-shades-600 text-sm font-normal leading-5'>
                {filter || 'Filter by'}
              </span>
              <iconComponents.util.ChevronDownIcon />
            </button>
          }
          options={[
            ...(filter ? ['clear filter'] : []),
            'upcoming',
            'ongoing',
            'concluded',
          ]}
          optionsText={(item) => item && ucword(item)}
          optionsValue={(item) => (item === 'clear filter' ? '' : item)}
          onChange={(e) => setFilter(e.currentTarget.value as Status)}
        />
        <div className='inline-flex items-center gap-3'>
          <Calendar
            placeholder={isTablet ? 'From' : 'Start Date'}
            className={isTablet ? '!min-w-[90px]' : ''}
            date={Date.parse(dates[0]) ? new Date(dates[0]) : undefined}
            onChange={(date) => {
              const newDates: typeof dates = [...dates]
              newDates[0] = date ? date.toISOString() : ''
              setDates(newDates)
            }}
          />
          <Calendar
            placeholder={isTablet ? 'To' : 'End Date'}
            className={isTablet ? '!min-w-[90px]' : ''}
            max={add(new Date(), { years: 1 })}
            date={Date.parse(dates[1]) ? new Date(dates[1]) : undefined}
            min={Date.parse(dates[0]) ? new Date(dates[0]) : undefined}
            popupClassName='right-0'
            onChange={(date) => {
              const newDates: typeof dates = [...dates]
              newDates[1] = date ? date.toISOString() : ''
              setDates(newDates)
            }}
          />
        </div>
        <Button onClick={openPopup} size='lg'>
          Create New Events
        </Button>
      </section>
      <CreateOrUpdateEvent onClose={closePopup} open={showCreateEvent} />
    </header>
  )
}

export default Header
