import { Status } from '@/apis/eventApis'
import TopEvents from './TopEvents'
import UpcomingEvents from './UpcomingEvents'

type Props = {
  search: string
  dates: [string, string]
  filter: Status | ''
}
const AllEvents = (props: Props) => {
  return (
    <article className='w-full flex-1 flex flex-col gap-0'>
      <TopEvents />
      <UpcomingEvents {...props} />
    </article>
  )
}

export default AllEvents
