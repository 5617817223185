import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { MenuItem } from '../dashboard.types'
import { breadCrumbsEmitter } from './header'
import Typography from '@/common/Typography'

const NavItem = (
  props: MenuItem & {
    onClick?: () => void
    queryKey?: string
    showText?: boolean
  }
) => {
  const { pathname } = useLocation()
  const { icon: Icon, name, url, onClick, showText } = props
  const isActive = pathname.includes(
    url || Math.random().toString(16).substring(4, 10)
  )
  const clickHandler = () => {
    if (url) {
      const map = new Map()
      map.set(url, name)
      breadCrumbsEmitter.emit('value', map)
    }

    onClick?.()
  }
  const innerElement = (
    <>
      <Icon className='w-6 h-6 min-w-[20px]' />
      <Typography
        as='button'
        button='sm'
        className={clsx(
          'md:hidden xl:block text-sm font-normal leading-5 whitespace-nowrap ',
          showText && 'md:!block'
        )}
      >
        {name}
      </Typography>
    </>
  )

  return !url ? (
    <li
      role='menuitem'
      onClick={clickHandler}
      className={clsx(
        'w-full flex cursor-pointer items-center py-2 px-4 h-12 rounded-lg capitalize md:flex-col  xl:justify-start xl:flex-row gap-2 text-sifuse-shades-700',
        isActive ? 'bg-sifuse-main-primary ' : 'sm:border-transparent',
        showText && 'sm:border-b-0 sm:!flex-row sm:!px-0'
      )}
    >
      {innerElement}
    </li>
  ) : (
    <Link
      role='menuitem'
      to={url}
      onClick={clickHandler}
      className={clsx(
        'w-full flex cursor-pointer items-center py-2 px-4 h-12 rounded-lg capitalize md:flex-col xl:justify-start xl:flex-row gap-2 text-sifuse-shades-700',
        isActive ? 'bg-sifuse-main-primary ' : 'sm:border-transparent',
        showText && 'sm:border-b-0 sm:!flex-row'
      )}
    >
      {innerElement}
    </Link>
  )
}

export default NavItem
