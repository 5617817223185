import composeEventHandlers from '@/helpers/composeEventHandlers'
import clsx from 'clsx'
import { AllHTMLAttributes, KeyboardEventHandler, useEffect } from 'react'
import Backdrop from './Backdrop'

interface ISidebar extends AllHTMLAttributes<HTMLDivElement> {
  onClose: () => void
  className?: string
  open: boolean
  children: React.ReactNode
  disableBackdrop?: boolean
  blur?: boolean
  reEvaluate?: boolean
  enableWarning?: boolean
}

const Sidebar = ({
  onClose,
  open,
  className,
  children,
  disableBackdrop,
  blur = false,
  onKeyDown,
  reEvaluate = false,
  enableWarning = true,
  style,
  ...rest
}: ISidebar) => {
  const keydownHandler: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Tab' || e.key === 'Shift + Tab') {
      e.stopPropagation()
    }
    if (e.key === 'Escape') {
      onClose?.()
    }
  }
  useEffect(() => {
    const abortHandler = (e: any) => {
      e.preventDefault()
      e.returnValue =
        'You are about to close the page, are you sure you are done'
    }
    if (!enableWarning) return
    if (open) {
      window.addEventListener('beforeunload', abortHandler)
    } else window.removeEventListener('beforeunload', abortHandler)

    return () => {
      window.removeEventListener('beforeunload', abortHandler)
    }
  }, [open, enableWarning])

  return open ? (
    <Backdrop
      blur={blur}
      open={open}
      onClose={disableBackdrop ? () => {} : onClose}
    >
      <div
        role='dialog'
        onKeyDown={composeEventHandlers(onKeyDown, keydownHandler)}
        tabIndex={open ? 0 : -1}
        onClick={(e) => e.stopPropagation()}
        className={clsx(
          'ease-in-out h-screen w-[640px] overflow-auto scroller rounded-l-lg bg-white -translate-x-[100vw] transition-transform absolute top-0 right-0',
          open && 'translate-x-0',
          className
        )}
        {...rest}
      >
        {children}
      </div>
    </Backdrop>
  ) : (
    <></>
  )
}

export default Sidebar
