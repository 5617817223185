import Typography from '@/common/Typography'
import EventCard from './EventCard'
import EmptyScreen from '../EmptyScreen'
import { useGetEvents } from '@/store/eventStore'
import InfiniteScroll, { InfiniteScrollResult } from '@/common/InfiniteScroll'
import ApiState from '@/common/ApiState'
import { Event, Status } from '@/apis/eventApis'

type Props = {
  search: string
  dates: [string, string]
  filter: Status | ''
}
const UpcomingEvents = (props: Props) => {
  const isSearching = () => {
    return props.dates[0] || props.search || props.filter
  }
  return (
    <section className='flex flex-col w-full flex-1 gap-4'>
      <Typography as='h2' heading='sm'>
        All Events
      </Typography>
      <InfiniteScroll
        data={{
          query: props.search,
          start: props.dates[0] ? props.dates[0].split('T')[0] : undefined,
          end: props.dates[1] ? props.dates[1].split('T')[0] : undefined,
          status: props.filter,
        }}
        getInfiniteData={useGetEvents}
      >
        {({ isInitialLoad, allPageData }: InfiniteScrollResult) => (
          <ApiState
            className='w-full'
            isLoading={isInitialLoad}
            isEmpty={allPageData?.length === 0}
            emptyComponent={
              <EmptyScreen
                title={
                  isSearching()
                    ? 'No events match your search criteria'
                    : 'No events yet'
                }
                subText={
                  isSearching()
                    ? 'Change your search criteria'
                    : 'You have no event yet added to your calendar'
                }
              />
            }
          >
            <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 content-stretch'>
              {allPageData.map((event: Event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </ul>
          </ApiState>
        )}
      </InfiniteScroll>
    </section>
  )
}

export default UpcomingEvents
