import Typography from '@/common/Typography'
import SelectInput from '../SelectInput'
import InputWithAdornment from '../InputWithAdornment'
import { FC, useState } from 'react'
import { pad } from '@/helpers/strings'
import { getMeridian } from '@/helpers/date'

type Props = {
  onChange: (date: Date) => void
  date?: Date
  showLabel?: boolean
}
const Time: FC<Props> = (props) => {
  const { date, showLabel = false, onChange } = props
  const [timeParts, setTimeParts] = useState({
    time: date
      ? `${pad(
          [0, 12, 24].includes(date.getHours()) ? 12 : date.getHours() % 12,
          2
        )}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}`
      : '',
    meridian: date ? getMeridian(date?.toISOString()) : 'am',
  })
  const isValidHour = (num: string) => {
    return Number(num) <= 12 && Number(num) >= 0
  }
  const isValidMinuteSecond = (num: string) => {
    return Number(num) <= 59 && Number(num) >= 0
  }
  return (
    <section className='flex flex-col gap-2 w-full justify-between items-start'>
      {showLabel && (
        <Typography as='h3' heading='2xs' color='#333'>
          Time
        </Typography>
      )}
      <div className='flex gap-2 items-start'>
        <InputWithAdornment
          inputMode={'numeric'}
          style={{
            border: 'none',
            backgroundColor: 'var(--shades100)',
          }}
          pattern='1[0-2]|0?[1-9]:[0-5]?[0-9]:[0-5]?[0-9]'
          placeholder='00:00:00'
          onChange={(e) => {
            const { value } = e.currentTarget
            const [hour, minute, seconds = '00'] = value.split(':')
            if (
              isValidHour(hour) &&
              isValidMinuteSecond(minute) &&
              isValidMinuteSecond(seconds)
            ) {
              const today = date || new Date()
              const newDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                Number(hour) +
                  (timeParts.meridian === 'pm'
                    ? Number(hour) === 12
                      ? 0
                      : 12
                    : Number(hour) === 12
                    ? -12
                    : 0),
                Number(minute),
                Number(seconds)
              )
              onChange(newDate)
            }
            setTimeParts((prev) => ({ ...prev, time: value }))
          }}
          onInput={(e) => {
            // /^[\d:]+$/g
            const pattern = /(?:(1[0-2]|0?[1-9])([0-5]?[0-9])?([0-5]?[0-9])?)/

            const target: any = e.currentTarget
            const plainText = target.value.replace(/:/g, '')
            const [hour, minute, seconds] = [
              plainText.slice(0, plainText.length <= 2 ? 1 : 2),
              plainText.slice(2, plainText.length <= 4 ? 3 : 4),
              plainText.slice(plainText.length <= 4 ? 3 : 4, 6),
            ]
            // console.log(
            //   pattern.test(target.value.replace(/:/g, '').replace(/^0/, '')),

            //   target.value.replace(/:/g, '').replace(/^0/, '').match(pattern),
            //   hour,
            //   minute,
            //   seconds
            // )
            if (
              !pattern.test(target.value.replace(/:/g, '').replace(/^0/, '')) ||
              !(
                (hour ? isValidHour(hour) : true) &&
                (minute ? isValidMinuteSecond(minute) : true) &&
                (seconds ? isValidMinuteSecond(seconds) : true)
              ) ||
              target.value.replace(/:/g, '').length > 6
            ) {
              target.value = target.value.slice(0, -1)
              e.target = target
              return false
            }

            const newValue = target.value
              .replace(/:/g, '')
              .replace(/^0/, '')
              .replace(
                pattern,
                (
                  match: string,
                  hour: string,
                  minute: string,
                  seconds: string,
                  index: number,
                  originalText: string
                ) => {
                  let text = ''
                  if (hour && isValidHour(hour)) {
                    text = pad(hour, 2)
                  }
                  if (hour && minute && isValidMinuteSecond(minute)) {
                    text += ':' + minute
                  }
                  if (text && seconds && isValidMinuteSecond(seconds)) {
                    text += ':' + seconds
                  }
                  return text
                }
              )

            target.value = newValue
            e.target = target

            e.preventDefault()
            return false
          }}
          value={timeParts.time}
        />
        <SelectInput
          menuClassName='left-auto right-0'
          options={['am', 'pm']}
          className='!lowercase border-none !bg-sifuse-shades-100'
          placeholder='am/pm'
          value={timeParts.meridian}
          onChange={(e) => {
            setTimeParts((prev) => ({
              ...prev,
              meridian: e.currentTarget.value,
            }))
            const today = date || new Date()
            const newDate = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              today.getHours() + (e.currentTarget.value === 'am' ? 0 : 12),
              today.getMinutes(),
              today.getSeconds()
            )
            onChange(newDate)
          }}
        />
      </div>
    </section>
  )
}

export default Time
